var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4  px-5"},[_c('h2',[_vm._v("Terminal-Verwaltung")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.comp_Terminals,"expanded":_vm.comp_Terminals.shooting_ranges,"single-expand":"","item-key":"id","sort-by":['created_at'],"sort-desc":[true],"loading":_vm.comp_Progress_loading,"loader-height":"8","show-expand":""},on:{"update:expanded":function($event){return _vm.$set(_vm.comp_Terminals, "shooting_ranges", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"success"}},[_c('h3',[_vm._v("Terminals")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pistol")]),_vm._v(" Neues Terminal ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_c('span',{staticClass:"text-h5"},[_vm._v("Neues Terminal")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Terminal Name","outlined":""},model:{value:(_vm.New_Terminal.name),callback:function ($$v) {_vm.$set(_vm.New_Terminal, "name", $$v)},expression:"New_Terminal.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{key:"id",attrs:{"items":_vm.comp_Ranges,"menu-props":{ maxHeight: '400' },"label":"Stände","item-text":"LangBez","item-value":"id","value":"id","multiple":"","outlined":"","hint":"Stände zuordnen, die vom Terminal verwalten werden soll","persistent-hint":""},model:{value:(_vm.New_Terminal.ranges),callback:function ($$v) {_vm.$set(_vm.New_Terminal, "ranges", $$v)},expression:"New_Terminal.ranges"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.add_new_Terminal}},[_vm._v(" Speichern ")])],1)],1)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getTerminals}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.created_at.replace(" ", "T")).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"error my-1",attrs:{"text":""},on:{"click":function($event){return _vm.openDelete_Dialog(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"mt-3 mb-3",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('span',[_vm._v("Zugeordnete Stände")]),_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Kurz")]),_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Bahnen")])])]),_c('tbody',_vm._l((item.shooting_ranges),function(range){return _c('tr',{key:range.id},[_c('td',[_vm._v(_vm._s(range.KurzBez))]),_c('td',[_vm._v(_vm._s(range.LangBez))]),_c('td',[_vm._v(_vm._s(range.totalplaces))])])}),0)]},proxy:true}],null,true)})],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Willst du diesen Eintrag wirklich löschen?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }